const API = {
    retrieveCollections(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/collections/`).then(
        (r) => r.json()
      );
    },
    retrieveItems(collectionId) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/${collectionId}`).then(
        (r) => r.json()
      );
    },
    retrieveGeneral(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/General_Template`).then(
        (r) => r.json()
      );
    },
    retrieveDivest(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Divest`).then(
        (r) => r.json()
      );
    },
    retrieveElectronics(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Electronics`).then(
        (r) => r.json()
      );
    },
    retrieveLAGS(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/LAGS`).then(
        (r) => r.json()
      );
    },
    retrieveSpecialNeeds(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Special_Needs`).then(
        (r) => r.json()
      );
    },
    retrieveSecurityScreening(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Security_Screening`).then(
        (r) => r.json()
      );
    },
    retrieveRefreshToken(item) {
      return fetch(`${process.env.REACT_APP_API_URL}/items/Refresh_State`).then(
        (r) => r.json()
      );
    },
    async getImageUrl(id) {
      return id
        ? await fetch(`${process.env.REACT_APP_API_URL}/assets/${id}`).then(
            async (it) => await it.blob()
          )
        : "";
    },
  };
  
  export default API;
  