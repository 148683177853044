import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { preloadImage, useLocalStorage } from '../../utils/functions';
import SlideItemPortrait from "./Slide";
import { dbgeneral, dbuservariables } from "../../utils/cache/db";
import { useLiveQuery } from "dexie-react-hooks";
import StoreData from "../../utils/cache/store";
import SlideItemV2 from "./SlideItem";
import SecurityScreening from "./SecurityScreening";
import storeData from "../../utils/cache/store";
import API from "../../utils/service";

const GeneralSlideData = (props) => {
  const [brandingColor, setBrandingColor] = useLocalStorage('branding_color', []);
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token', []);
  const [lastUpdatedAt, setLastUpdatedAt] = useLocalStorage('last_updated_at', null);
  
  const { collectionId, itemId } = useParams();
  const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(
    `${collectionId}_index`,
    0
    );
  const [items, setItems] = useLocalStorage(collectionId, []);
  const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
  const [currentItem, setCurrentItem] = useState({});

  const [bodyHeaderEN, setBodyHeaderEN] = useState('');
  const [bodyHeaderFR, setBodyHeaderFR] = useState('');
  const [bodyTextEN, setBodyTextEN] = useState('');
  const [bodyTextFR, setBodyTextFR] = useState('');
  const [alertsAndRemindersEN, setAlertsAndRemindersEN] = useState('');
  const [alertsAndRemindersFR, setAlertsAndRemindersFR] = useState('');
  const [bodyImage, setBodyImage] = useState(null);
  const [logoImage, setLogoImage] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('');

  const [id , setId] = useState('');

  const general = useLiveQuery(() => dbgeneral.general.toArray());
  const divest = useLiveQuery(() => dbgeneral.divest.toArray());
  const electronics = useLiveQuery(() => dbgeneral.electronics.toArray());
  const lags = useLiveQuery(() => dbgeneral.lags.toArray());
  const specialneeds = useLiveQuery(() => dbgeneral.specialneeds.toArray());
  const securityscreening = useLiveQuery(() => dbgeneral.securityscreening.toArray());

  const variables = useLiveQuery(() => dbuservariables.variables.toArray());
  // const refreshTokenVar = useLiveQuery(() => dbuservariables.refreshToken.toArray());

  useEffect(() => {
    async function check() {
      API.retrieveRefreshToken()
        .then((response) => {
          const updatedAt = response.data.date_updated;
          
          if (!lastUpdatedAt || lastUpdatedAt !== updatedAt) {
            setLastUpdatedAt(updatedAt);
            // localStorage.clear();
            StoreData();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    check()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedAt]);




  useEffect(() => {    
    switch (collectionId) {
      case 'General_Template':
        if (!general) {
          // LOADING OF THE DATA
          console.log("general is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got general");
          setItems(general);
          console.log('general', items)
        }
        break;
      case 'LAGS':
        if (!lags) {
          // LOADING OF THE DATA
          console.log("lags is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got lags");
          setItems(lags);
          console.log(items)
        }
        break;
      case 'Divest':
        if (!divest) {
          // LOADING OF THE DATA
          console.log("divest is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got divest");
          setItems(divest);
          console.log(items)
        }
        break;
      case 'Special_Needs':
        if (!specialneeds) {
          // LOADING OF THE DATA
          console.log("specialneeds is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got specialneeds");
          setItems(specialneeds);
          console.log(items)
        }
        break;
      case 'Security_Screening':
        if (!securityscreening) {
          // LOADING OF THE DATA
          console.log("securityscreening is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got securityscreening");
          setItems(securityscreening);
          console.log(items)
        }
        break;
      case 'Electronics':
        if (!electronics) {
          // LOADING OF THE DATA
          console.log("spelectronicsecialneeds is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got electronics");
          setItems(electronics);
          console.log(items)
        }
        break;
      default:
        if (!general) {
          // LOADING OF THE DATA
          console.log("general is loading");
        } else {
          // ONCE DATA IS LOADED SET THE ITEMS
          console.log("got general");
          setItems(general);
          console.log(items)
        }
        break;
    }




    // THIS IS HANDLELING THE CURRENT ITEM VIA THE LOCAL STORAGE
    if (itemId) {
      const itemIndex = items.findIndex((item) => item.id == itemId);

      if (itemIndex !== -1) {
        setCurrentItem(items[itemIndex]);
        console.log(currentItem)
      }
    } else {
      // REFRESBING THE PAGE WILL GIVE NEXT ITEM
      let i = currentItemIndex;
 
      // ID OF THE CURRENT ITEM
      if (!items[i]) {
        if (items.length && items[i % items.length]) {
          i = i % items.length;
        }
        if (items[0]) {
          i = 0;
        }
      }

      items.length && setCurrentItem(items[i]);
      storeCurrentItemIndex(i);

      // currentItem IS THE CURRENT ITEM FROM THE REFRESH STATE
      // console.log(currentItemIndex)
      setBodyHeaderEN(currentItem.bodyHeaderEN);
      setBodyHeaderFR(currentItem.bodyHeaderFR);
      setBodyTextEN(currentItem.bodyTextEN);
      setBodyTextFR(currentItem.bodyTextFR);
      setBodyImage(currentItem.bodyImage);
      setLogoImage(currentItem.logoImage);
      setAlertsAndRemindersEN(currentItem.alertsAndRemindersEN);
      setAlertsAndRemindersFR(currentItem.alertsAndRemindersFR);
      setBackgroundColor(currentItem.backgroundColor);
      setId(currentItem.id);
    }
  }, [currentItemIndex, items, itemId, collectionId, general, variables, divest, electronics, lags, specialneeds]);

console.log(currentItem)

    return (
        <div>
            {collectionId === 'Security_Screening' ? (
               <SecurityScreening {...{
                bodyHeaderEN,
                bodyHeaderFR,
                bodyTextEN,
                bodyTextFR,
                alertsAndRemindersEN,
                alertsAndRemindersFR,
                bodyImage,
                logoImage,
                id,
                backgroundColor,
              }} />
            ) : (
              <SlideItemV2 {...{
                bodyHeaderEN,
                bodyHeaderFR,
                bodyTextEN,
                bodyTextFR,
                bodyImage,
                logoImage,
                id,
                backgroundColor,
              }} />
            )}
        </div>
    );
};

export default GeneralSlideData;