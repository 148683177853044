import API from "../../utils/service";
import { dbgreenfeeds, dbgeneral, dbuservariables } from "./db";
import {useState, useEffect} from 'react';

export default function storeData () {
    //get API retrieveWaterTips and map through it and set the state for each item
    
    API.retrieveGeneral().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.general.bulkPut([
                {
                    id: item.id,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    backgroundColor: item.Background_Color,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });
    API.retrieveDivest().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.divest.bulkPut([
                {
                    id: item.id,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    backgroundColor: item.Background_Color,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });
    API.retrieveElectronics().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.electronics.bulkPut([
                {
                    id: item.id,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    backgroundColor: item.Background_Color,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });
    API.retrieveLAGS().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.lags.bulkPut([
                {
                    id: item.id,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    backgroundColor: item.Background_Color,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });
    API.retrieveSpecialNeeds().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.specialneeds.bulkPut([
                {
                    id: item.id,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    backgroundColor: item.Background_Color,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });
    API.retrieveSecurityScreening().then((response) => {
        // map each item and console log it
        response.data.map((item) => {
            console.log(item);
            // add each item to the database
            dbgeneral.securityscreening.bulkPut([
                {
                    id: item.id,
                    bodyHeaderEN: item.EN_Body_Header,
                    bodyHeaderFR: item.FR_Body_Header,
                    bodyTextEN: item.EN_Body_Text,
                    bodyTextFR: item.FR_Body_Text,
                    bodyImage: item.Body_Image,
                    logoImage: item.Logo_Image,
                    backgroundColor: item.Background_Color,
                    alertsAndRemindersEN: item.EN_Alerts_And_Reminders,
                    alertsAndRemindersFR: item.FR_Alerts_And_Reminders,
                    lastUpdated: item.Last_Updated,
                },
            ]);
        });
    });

    API.retrieveRefreshToken().then((response) => {
        // map each item and console log it
        console.log(response.data.Refresh_Token);
        // response.data.map((item) => {
        //     console.log(item);
        //     // add each item to the database
            dbuservariables.refreshToken.bulkPut([
                {
                    id: response.data.id,
                    refreshToken: response.data.Refresh_Token,
                },
            ]);
        // });
    });
};
 
    