// db.js
import Dexie from 'dexie';

// export const dbgreenfeeds = new Dexie('greenfeeds');
// dbgreenfeeds.version(1).stores({
//     watertips: '++id, tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR',
//     energytips: '++id, tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR',
//     wastetips: '++id, tipTitleEN, tipTitleFR, tipEN, tipFR, tipImage, qrCodeURLEN, qrCodeURLFR, qrCodeHeaderEN, qrCodeHeaderFR, qrCodeSubHeaderEN, qrCodeSubHeaderFR',
// });

export const dbgeneral = new Dexie('general');
dbgeneral.version(1).stores({
    general: '++id, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, lastUpdated, backgroundColor',
    lags: '++id, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, lastUpdated, backgroundColor',
    divest: '++id, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, lastUpdated, backgroundColor',
    specialneeds: '++id, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, lastUpdated, backgroundColor',
    securityscreening: '++id, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, lastUpdated, backgroundColor, alertsAndReminderEN, alertsAndReminderFR ',
    electronics: '++id, bodyHeaderEN, bodyHeaderFR, bodyTextEN, bodyTextFR, bodyImage, logoImage, lastUpdated, backgroundColor',
});

export const dbuservariables = new Dexie('variables');
dbuservariables.version(1).stores({
    variables: '++id, brandingcolor',
    refreshToken: '++id, refreshToken',
});


