import { AnimatePresence, motion } from 'framer-motion';
import React, { useMemo, useState, useEffect } from 'react';
import { getContrastColor } from '../../utils/colors';
import API from '../../utils/service';
// import Image from '../Common/Image';
// import { SlideItemV2Props } from './types';

const SecurityScreening = ({
  bodyHeaderEN,
  bodyHeaderFR,
  bodyTextEN,
  bodyTextFR,
  bodyImage,
  logoImage,
  backgroundColor,
  alertsAndRemindersEN,
  alertsAndRemindersFR,
  id,

}) => {
  const BACKGROUND_COLOR = backgroundColor; 
  // console.log(BACKGROUND_COLOR)
  const bodyTextColor = getContrastColor(BACKGROUND_COLOR);
  const [englishBody, isBodyEnglish] = useState(false);
  const [bodyImageBlob, setBodyImageBlob] = useState(null);
  const [logoImageBlob, setLogoImageBlob] = useState(null);

  setInterval(() => {
    // isHeaderEnglish(true);
    isBodyEnglish(true);
    // isFooterEnglish(true);
  }, 7000);

  useEffect(() => {
    const getImage = async () => {
      // Header Image Generation
      // const preHeaderImage = await API.getImageUrl(headerImage);
      // // console.log(preHeaderImage);
      //   // create a url for the image
      // const headerImageUrl = URL.createObjectURL(preHeaderImage);
      // // console.log(headerImageUrl);
      // setHeaderImageBlob(headerImageUrl);
      
      // Body Image Generation
      const preBodyImage = await API.getImageUrl(bodyImage);
      // console.log(preBodyImage);
        // create a url for the image
      const preBodyImageUrl = URL.createObjectURL(preBodyImage);
      // console.log(headerBodyUrl);
      setBodyImageBlob(preBodyImageUrl);
      
      // Body Image Generation
      const preFooterImage = await API.getImageUrl(logoImage);
      // console.log(preBodyImage);
        // create a url for the image
      const preFooterImageUrl = URL.createObjectURL(preFooterImage);
      // console.log(preFooterImageUrl);
      setLogoImageBlob(preFooterImageUrl);
    }

    getImage()
      .catch((err) => {
          console.log(err);
      });
  }, [logoImage, bodyImage]);

  return (
    <section
      className="w-full bg-cover bg-no-repeat bg-center min-h-screen overflow-hidden"
      style={{backgroundColor: BACKGROUND_COLOR, backgroundBlendMode: 'multiply'}}
    >
      
      <header className="flex flex-col items-start">
        {!alertsAndRemindersEN ? (
          <img
            src={bodyImageBlob}
            className="rounded-[3.5rem] max-h-[804px] ml-[60px] mt-[20px]"
          />
        ) : (
          <div className="flex flex-col font-helvetica items-center w-full h-64 text-[64px] text-left align-middle justify-center p-10 font-semibold leading-[1.0] bg-gray-300	">
            <p>{englishBody === true ? alertsAndRemindersFR : alertsAndRemindersEN}</p>
          </div>
          )}
        
      </header>

      <main className="flex flex-col items-center">
        {/* {bodyImage && (
          <img
            src={bodyImageBlob}
            className="rounded-[3.5rem] max-h-[804px] mt-[20px]"
          />
        )} */}
        {bodyHeaderEN ? (
          <AnimatePresence>
            <motion.div
              data-testid="slide-body__text"
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <p
                className="text-white text-[128px] font-helvetica mt-48  font-bold text-left leading-[1.0] px-20 "
                style={{ color: bodyTextColor }}
              >
                {englishBody === true ? bodyHeaderFR : bodyHeaderEN}
              </p>
              <p
                className="text-white text-[64px] font-ariel text-left leading-none p-20"
                style={{ color: bodyTextColor }}
                >
                {englishBody === true ? bodyTextFR : bodyTextEN}
              </p>
            </motion.div>
          </AnimatePresence>
        ):(
            <AnimatePresence>
            <motion.div
              data-testid="slide-body__text"
              animate={{ opacity: 1, y: 0 }}
              initial={{ opacity: 0, y: 30 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            >
              <p
                className="text-white text-[64px] font-ariel font-bold text-left leading-none p-20"
                style={{ color: bodyTextColor }}
                >
                {englishBody === true ? bodyTextFR : bodyTextEN}
              </p>
            </motion.div>
          </AnimatePresence>
        )}
        {logoImage && (
          <img
            src={logoImageBlob}
            className="w-full bottom-0 absolute"
          />
        )}

      </main>
      
    </section>
  );
};

export default SecurityScreening;
