import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import GeneralSlideData from './components/General/SlideData';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>

          {/* <Route path="/general/" exact element={<GeneralSlideData />} /> */}
          <Route path="/:collectionId" exact element={<GeneralSlideData />} />
          <Route path="/" exact element={<GeneralSlideData />} />
          {/* <Route path="/emergency/" exact element={<EmergencySlideData />} />
          <Route path="/emergency/:collectionId" exact element={<EmergencySlideData />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
